<script>
import { api } from "@/state/services";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      token: this.$route.params.token,
    };
  },
  methods: {
    getToken() {
      if (this.token) {
        api.post("email/token", {
          token: this.token,
        }).then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(response.data.message);
            this.$router.push('/');
          } else {
            this.$noty.error(response.data.message);
            this.$router.push('/email');
          }
        });
      } else {
        this.$router.push('/');
      }
    },
  },
  mounted() {
    this.getToken();
  },
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-center">
    <b-spinner class="ml-2 align-middle" variant="default" role="status"></b-spinner>
  </div>
</template>

<style scoped>
.d-flex {
  height: 100vh !important;
}
</style>
